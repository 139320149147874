import styled from "styled-components";
import Image from "components/Image";
import { media } from "components/Theme/default-theme";
import LearnMoreBtn from "components/LearnMoreBtn";

export const PressItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 1 0 100%;
  margin: 2rem 0;
`;

export const PressImage = styled(Image)`
  img {
    width: 100%;
    height: 100%;
    object-fit: contain !important;
  }
  margin: 0;
  height: 40px;
`;

export const Date = styled.span`
  padding-top 1em;
`;

export const NewsTitle = styled.h4`
  font-size: 1.25rem;
  line-height: 1.5em;
  text-transform: uppercase;
`;

export const NewsSubtitle = styled.h5`
  font-size: 1rem;
`;

export const PressLink = styled.a`
  color: #ff5f00;
  cursor: pointer;
  font-family: "Gotham", "Helvetica", "Arial", sans-serif;
  text-decoration: none;
  font-size: 1rem;
  line-height: 1.75em;
  vertical-align: middle;
  margin: 2rem 0 0;
`;

export const ItemUL = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-inline-start: 0;
`;

export const SeeMoreButton = styled(LearnMoreBtn)`
  position: absolute;
  bottom: 5rem;
  align-self: center;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 0 10rem;
  margin: 0 1rem;

  ${media.laptop} {
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;

    ${PressItem} {
      flex: 0 0 30%;
    }
  }
`;
export const PageWrapper = styled.div`
  width: 90%;
  margin: 0 auto;

  p {
    margin: 1rem 2.5%;
  }
`;

export const MediaKit = styled.div``;
