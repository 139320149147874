import React from "react";
import PropTypes from "prop-types";
import {
  PageWrapper,
  PressItem,
  PressLink,
  PressImage,
  Date,
  MediaKit,
  NewsTitle,
  NewsSubtitle,
  Container,
  SeeMoreButton,
} from "./styles";
import HeroImage from "components/HeroImage";
import ImageTiles from "components/ImageTiles";
import { SectionTitle } from "components/ImageTiles/styles";
import { Link } from "@racepointenergy/gatsby-theme-sitespark/src/components/Html";
import { learnMoreBtnType } from "components/LearnMoreBtn";

function PressContent({ press }) {
  return (
    <>
      <SectionTitle
        data-aos="fade-in"
        data-aos-duration="1000"
        data-aos-easing="ease-in"
        data-aos-once="true"
      >
        IN THE NEWS
      </SectionTitle>
      <Container>
        {press.map(({ title, linkUrl, image, date }, index) => (
          <PressItem key={`${title}-${index}`}>
            <PressImage item={image} />
            <PressLink href={linkUrl}>"{title}"</PressLink>
            <Date>{date}</Date>
          </PressItem>
        ))}
        <SeeMoreButton
          btnType={learnMoreBtnType.fullOrangeBtn}
          btnUrl="/in-the-news"
          openInNewTab={false}
        >
          SEE ALL
        </SeeMoreButton>
      </Container>
    </>
  );
}

PressContent.propTypes = {
  press: PropTypes.array,
};

function NewsContent({ news }) {
  return (
    <>
      <SectionTitle
        data-aos="fade-in"
        data-aos-duration="1000"
        data-aos-easing="ease-in"
        data-aos-once="true"
      >
        COMPANY NEWS
      </SectionTitle>
      <Container>
        {news.map(({ title, subtitle, linkUrl, image }, index) => (
          <PressItem key={`${title}-${index}`}>
            <PressImage item={image} />
            <NewsTitle href={linkUrl}>{title}</NewsTitle>
            <NewsSubtitle>{subtitle}</NewsSubtitle>
            <Link to={linkUrl}>Read More</Link>
          </PressItem>
        ))}
        <SeeMoreButton
          btnType={learnMoreBtnType.fullOrangeBtn}
          btnUrl="/news"
          openInNewTab={false}
        >
          SEE ALL
        </SeeMoreButton>
      </Container>
    </>
  );
}

PressContent.propTypes = {
  press: PropTypes.array,
};

export function Press({ press, news }) {
  return (
    <>
      <HeroImage
        heroTitle="PRESS"
        subtitle="For media inquiries, email us at [pr@savant.com](mailto:pr@savant.com)"
        heroImage={{
          publicURL: "https://cdn.savant.com/image/PressMan-Desktop-02_0.jpg",
        }}
      />
      <PageWrapper>
        <MediaKit>
          <ImageTiles
            sectionTitle="Media Kit"
            ImageTilesList={[
              {
                label: "Brand Kit",
                linkUrl:
                  "https://media.savant.com/web/2f14f5286f99c885/brand-kit/",
                image: {
                  publicURL:
                    "https://cdn.savant.com/image/LG_media-kit_logo-01_2.png",
                },
              },
              {
                label: "Savant App",
                linkUrl:
                  "https://media.savant.com/web/26fb60f05e0090ad/savant-pro-ui/",
                image: {
                  publicURL: "https://cdn.savant.com/image/SAVANT+PRO+UI.png",
                },
              },
              {
                label: "Savant Pro Remote",
                linkUrl:
                  "https://media.savant.com/web/5c629abfce5a5307/savant-pro-remote/",
                image: {
                  publicURL:
                    "https://cdn.savant.com/image/DirectYourHome-Tablet.jpg",
                },
              },
              {
                label: "Lifestyle + UI",
                linkUrl:
                  "https://media.savant.com/web/3b78d72de1d8d16f/lifestyle---ui/",
                image: {
                  publicURL:
                    "https://cdn.savant.com/image/LIFESTYLE+%2B+UI_2.png",
                },
              },
              {
                label: "Lifestyle Photography",
                linkUrl:
                  "https://media.savant.com/web/7af38fae276da247/lifestyle/",
                image: {
                  publicURL:
                    "https://cdn.savant.com/image/LIFESTYLE+%281%29_0.png",
                },
              },
              {
                label: "Savant App Usage",
                linkUrl:
                  "https://media.savant.com/web/1f518d3cf9d5fbc6/pro-app-usage/",
                image: {
                  publicURL: "https://cdn.savant.com/image/PRO+APP+USAGE.png",
                },
              },
            ]}
          ></ImageTiles>
        </MediaKit>
        <PressContent press={press} />
        <NewsContent news={news} />
        <SectionTitle
          data-aos="fade-in"
          data-aos-duration="1000"
          data-aos-easing="ease-in"
          data-aos-once="true"
        >
          ABOUT SAVANT
        </SectionTitle>
        <p>
          Savant was founded in 2005 by a team of telecommunications engineers
          and business leaders with a mission to design a technology foundation
          that could make all homes smart, impacting the entertainment,
          lighting, security and environmental experiences all without the need
          for expensive, propriety, custom solutions that quickly become
          obsolete.
        </p>
        <p>
          Today, Savant builds on that innovative spirit and strives to deliver
          not only the best experience in smart home and smart working
          environments but also the latest in smart power technology.
        </p>
        <p>
          As the first home automation company to embrace mobile technology, we
          understood early that the customer experience could extend beyond the
          home and open up new possibilities for control.
        </p>
        <p>
          Over the years, our technology has evolved and our company has grown,
          but our goal remains the same. We've become the home automation brand
          of choice for the world's most luxurious homes, castles, and even
          yachts.
        </p>
        <br />
        <br />
        <br />
        <br />
      </PageWrapper>
    </>
  );
}

Press.propTypes = {
  press: PropTypes.array,
};
